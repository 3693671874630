/* global Swiper */
/* global gsap */
/* eslint-disable max-len */

import Cookies from 'js-cookie';
import { gsap } from 'gsap';
import tippy from 'tippy.js';
import Fullpage from 'fullpage.js'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin.js';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
import { Swiper, Pagination, Navigation, EffectFade, Thumbs } from 'swiper';
import 'lightcase/vendor/jQuery/jquery.events.touch.js';
import 'lightcase';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

Swiper.use([Pagination, Navigation, EffectFade, Thumbs]);

(
    function ($) {
        'use strict';

        var Site = {
            $body: $('html'),
            $searchContainer: $('.form-search'),
            $searchSuggestionsContainer: $('.search-suggestions'),

            $btnToTop: $('[data-scroll-to-top]'),
            $lightCase: $('a[data-rel^=lightcase]'),
            $socialShare: $('.social-share'),

            $language: $('[data-language]'),

            $maps: $('.maps'),

            $languageMessage: $('.language-message'),

            $sliderProducts: $('.swiper--products-slider'),
            $sliderMarkets: $('.swiper--markets-slider'),
            $sliderHowTos: $('.swiper--howtos-slider'),

            $blockHighlights: $('.highlight--hero'),
            $blockPaginations: $('.block--pagination'),
            $blockTaggbox: $('.taggbox .taggbox__title'),

            $anchorNavigation: $('.navigation--anchor'),

            $responsiveTables: $('table:not(.not-responsive)'),
            $responsiveEmbedVideos: $('iframe[src*="youtube"], iframe[src*="vimeo"]'),

            // dupli-color
            $tooltip: $('.tooltip'),
            $gallery: $('.gallery__top .swiper'),
            $galleryThumbnail: $('.gallery__thumbnail .swiper'),

            $Fullpage: null,

            fullpageMinHeight: 200,  /** 950 makes more sense */
            fullpageMaxWidth:  3000, /** We need zoom on big screens */

            /**
             * Toggle the search form in the header.
             *
             * @param element
             */
            onToggleSearchForm: function (element) {
                var $input = $('input', this.$searchContainer);

                $(element.currentTarget).toggleClass('active');

                this.$body.toggleClass('primary-search-active');

                if ($input.length) {
                    console.log(this.$Fullpage);
                    if (this.$body.hasClass('primary-search-active')) {
                        if (this.$Fullpage) {
                            this.$Fullpage.setAllowScrolling(false);
                            console.log('Disabling scrolling')
                        }

                        setTimeout($.proxy(function () {
                            $input.focus();
                        }, this), 500);
                    } else {
                        $input.blur();
                        if (this.$Fullpage) {
                            this.$Fullpage.setAllowScrolling(true);
                            console.log('Enabling scrolling')
                        }
                    }
                }

                return false;
            },

            /**
             * Toggle the language switch.
             *
             * @param element
             */
            onToggleLanguage: function (element) {
                $(element.currentTarget).toggleClass('active');

                this.$language.toggleClass('active');

                return false;
            },

            /**
             * Check the search form for an empty value.
             *
             * @param element
             */
            onSubmitSearchForm: function (element) {
                var $input = $('input', $(element.currentTarget));

                if ($input.val() === '' || $input.val() === $input.attr('placeholder')) {
                    alert($(element.currentTarget).attr('data-alert'));

                    return false;
                }

                return true;
            },

            /**
             * Toggle the main navigation.
             *
             * @param element
             */
            onToggleNavigation: function (element) {
                $(element.currentTarget).toggleClass('active');

                this.$body.toggleClass('primary-navigation-active');

                return false;
            },

            /**
             * Toggle sub navigation in the main navigation.
             *
             * @param element
             */
            onToggleSubNavigation: function (element) {
                $(element.currentTarget).parent('li').toggleClass('subnav-active');

                $(element.currentTarget).find('> ul').stop().slideToggle();

                return false;
            },

            /**
             * Toggle brand list in header.
             *
             * @param element
             */
            onToggleBrandList: function (element) {
                $(element.currentTarget).parents('.brands-toggle').toggleClass('active');

                return false;
            },

            /**
             * Scroll to the top of the page.
             */
            onHandleScrollToTop: function () {
                $('html,body').animate({
                    scrollTop: 0
                });

                return false;
            },

            /**
             * Scroll to the top of the page.
             */
            onHandleScrollNext: function (element) {
                var $element = $(element.currentTarget).parents('.block').next(),
                    heightHeader = $('.header').outerHeight();

                if (Site.$anchorNavigation.length > 0) {
                    heightHeader += Site.$anchorNavigation.outerHeight();
                }

                $('html, body').animate({
                    scrollTop: $element.offset().top - heightHeader
                });
            },

            /**
             * Toggle social share.
             *
             * @param element.
             */
            onToggleSocialShare: function (element) {
                $(element.currentTarget).parents('.social-share').toggleClass('active');

                return false;
            },

            /**
             * Copy share URL to clipboard.
             *
             * @param element.
             */
            onHandleSocialShareUrl: function (element) {
                var copy = false;
                var $element = $(element.currentTarget);

                $element.select();

                try {
                    copy = document.execCommand('copy');
                } catch (err) {
                    copy = false;
                }

                if (copy) {
                    $element.addClass('active').val($element.attr('data-success'));
                } else {
                    $element.removeClass('active');
                }
            },

            /**
             * Click hash.
             *
             * @param element
             */
            onHandleHash: function (element) {
                if (element.currentTarget.hash.substr(1).length > 1) {
                    var $element = $('#' + element.currentTarget.hash.substr(1));

                    if ($element.length) {
                        $('html, body').animate({
                            scrollTop : $element.offset().top - 50
                        }, 500);
                    }
                }
            },

            /**
             * Toggle FAQ question.
             *
             * @param element
             */
            onToggleFaqQuestion: function (element) {
                $(element.currentTarget).parents('[data-faq-question]').toggleClass('active');

                return false;
            },

            hasCookiesEnabled: function () {
                var cookieEnabled = (navigator.cookieEnabled) || false;

                if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
                    document.cookie = 'testcookie';
                    cookieEnabled   = (document.cookie.indexOf('testcookie') !== -1) || false;
                }

                return cookieEnabled;
            },

            getUrlParameter: function (sParam) {
                /* eslint-disable valid-typeof */
                var sPageURL        = window.location.search.substring(1);
                var sURLVariables   = sPageURL.split('&');
                var sParameterName;
                var i;

                for (i = 0; i < sURLVariables.length; i++) {
                    sParameterName = sURLVariables[i].split('=');

                    if (sParameterName[0] === sParam) {
                        return typeof sParameterName[1] === undefined || decodeURIComponent(sParameterName[1]);
                    }
                }

                /* eslint-enable valid-typeof */
                return '';
            },

            /**
             * Check country of visitor.
             */
            initCountryCheck: function () {
                if (!this.hasCookiesEnabled()) {
                    return false;
                }

                $.ajax({
                    url         : '/assets/components/site/connector.php',
                    data        : {
                        action      : 'web/language/suggestion',
                        id          : $('body').attr('data-res'),
                        language    : this.getUrlParameter('language')
                    },
                    dataType    : 'JSON',
                    complete    : $.proxy(function (result) {
                        if (result.responseJSON) {
                            if (result.responseJSON.success) {
                                $(result.responseJSON.object.html).insertBefore('header');
                            }
                        }
                    }, this)
                });

                return '';
            },

            /**
             * Detect window scroll set body classes and animate blocks.
             */
            initWindowScroll: function () {
                var $window         = $(window);
                var windowScroll    = 0;

                $window.scroll($.proxy(function () {
                    var currentScroll = $window.scrollTop();

                    if (currentScroll <= 0) {
                        this.$body.removeClass(['scroll-up', 'scroll-down']);
                    } else if (currentScroll > windowScroll) {
                        this.$body.removeClass('scroll-up').addClass('scroll-down');
                    } else {
                        this.$body.addClass('scroll-up').removeClass('scroll-down');
                    }

                    this.$body.toggleClass('header-fixed', currentScroll > $('header').outerHeight());

                    windowScroll = currentScroll;
                }, this)).trigger('scroll');
            },

            /**
             * Zoom page on 4K screens
             */
            initWindowZoom: function () {
                if (document.documentElement.clientWidth > this.fullpageMaxWidth) {
                    document.body.style.zoom = '150%';
                }
            },

            /**
             * Init Lightcase.
             */
            initLightCase: function () {
                this.$lightCase.lightcase({
                    showSequenceInfo        : false,
                    fullscreenModeForMobile : true,
                    swipe                   : true,
                    showTitle               : false,
                    maxWidth                : 1280,
                    maxHeight               : 720,
                    video                   : {
                        width                     : 1280,
                        height                    : 720
                    }
                });
            },

            initMultiSelect: function () {
                /* eslint-disable no-param-reassign */
                $.fn.multiselect.Constructor.prototype.defaults.nSelectedText = $('[data-select-js]').attr('data-selected-text');
                /* eslint-enable no-param-reassign */

                $('[data-select-js]').multiselect({
                    numberDisplayed: 1
                });
            },

            /**
             * Init the markets slider.
             */
            initSliderMarkets: function () {
                /* eslint-disable no-new */
                this.$sliderMarkets.each(function (index, slider) {
                    var $slider = $(slider);

                    new Swiper(slider, {
                        slidesPerView   : 'auto',
                        navigation      : {
                            nextEl          : $('.swiper-button-next', $slider)[0],
                            prevEl          : $('.swiper-button-prev', $slider)[0]
                        }
                    });
                });
                /* eslint-enable no-new */
            },

            /**
             * Init the How To slider.
             */
            initSliderHowTo: function () {
                /* eslint-disable no-new */
                this.$sliderHowTos.each(function (index, slider) {
                    var $slider = $(slider);

                    new Swiper(slider, {
                        slidesPerView   : 'auto',
                        navigation      : {
                            nextEl          : $('.swiper-button-next', $slider)[0],
                            prevEl          : $('.swiper-button-prev', $slider)[0]
                        }
                    });
                });
                /* eslint-enable no-new */
            },

            /**
             * Init the products slider.
             */
            initSliderProducts: function () {
                /* eslint-disable no-new */
                this.$sliderProducts.each(function (index, slider) {
                    var $slider = $(slider);

                    new Swiper(slider, {
                        slidesPerView   : 'auto',
                        navigation      : {
                            nextEl          : $('.swiper-button-next', $slider)[0],
                            prevEl          : $('.swiper-button-prev', $slider)[0]
                        }
                    });
                });
                /* eslint-enable no-new */
            },

            /**
             * Init navigator functions.
             */
            initNavigator: function () {
                if (Site.$maps.length > 0) {
                    if (navigator.geolocation) {
                        Site.$maps.addClass('loading-location');
                        navigator.geolocation.getCurrentPosition($.proxy(function (position) {
                            Site.initMaps(position);
                        }),
                        function () {
                            Site.$maps.removeClass('loading-location');

                            Site.initMaps();
                        }, { timeout:20000 });
                    } else {
                        Site.initMaps();
                    }
                }
            },

            /**
             * Init the maps.
             *
             */
            initMaps: function (currentLocation) {
                var latitude    = 51.940420,
                    longitude   = 6.481510;

                if (currentLocation !== undefined) {
                    latitude    = currentLocation.coords.latitude;
                    longitude   = currentLocation.coords.longitude;

                    Site.$maps.removeClass('loading-location');
                }

                var $maps = this.$maps.Maps({
                    zoom        : 12,
                    center      : {
                        lat         : latitude,
                        lng         : longitude
                    },
                    markerIcon  : {
                        iconUrl     : '/assets/img/maps/marker-2.png',
                        iconSize    : [32, 46],
                        iconAnchor  : [16, 46]
                    }
                });

                if ($maps.length) {
                    var $filter     = $('[data-maps-filter]');
                    var $overview   = $('[data-maps-overview]');

                    if ($filter.length) {
                        $filter.on('submit', function (element) {
                            var $form   = $(element.currentTarget);
                            var params  = {
                                action      : 'web/dealer/list',
                                context     : $overview.data('context'),
                                search      : $('input[name="search"]', $filter).val()
                            };

                            ($form.attr('data-maps-filter-params') || '').split(',').forEach(function (param) {
                                if (param) {
                                    params[param.split(':', 2)[0]] = param.split(':', 2)[1];
                                }
                            });

                            if (currentLocation !== undefined) {
                                params.latitude  = latitude;
                                params.longitude = longitude;
                            }

                            $.ajax({
                                url         : '/assets/components/site/connector.php?' + $.param(params),
                                dataType    : 'JSON',
                                complete    : $.proxy(function (results) {
                                    if (results.responseJSON) {
                                        $overview.empty();

                                        $maps.trigger('reset.maps');

                                        if (results.responseJSON.output) {
                                            if (currentLocation !== undefined) {
                                                $maps.trigger('setMarker.maps', [latitude, longitude, {
                                                    icon: '/assets/img/maps/marker-location.png'
                                                }]);
                                            }

                                            results.responseJSON.output.forEach(function (result) {
                                                if (result.latitude && result.longitude) {
                                                    var $html = $(result.html);

                                                    $('[data-toggle-dealer]', $html).on('click', function () {
                                                        $html.toggleClass('active');

                                                        if ($html.hasClass('active')) {
                                                            $maps.trigger('updateMarker.maps', [result.latitude, result.longitude, true]);
                                                        } else {
                                                            $maps.trigger('updateMarker.maps', [result.latitude, result.longitude, false]);
                                                            $maps.trigger('bindZoomMarkers.maps');
                                                        }

                                                        return false;
                                                    });

                                                    $overview.append(
                                                        $('<div class="dealers-map-overview__item">').append($html)
                                                    );

                                                    $maps.trigger('setMarker.maps', [result.latitude, result.longitude, {
                                                        center   : false,
                                                        icon     : '/assets/img/maps/marker-2.png',
                                                        callback : function () {
                                                            if ($(window).width() < 768) {
                                                                $('html, body').animate({ scrollTop: 0 });
                                                            }

                                                            $html.addClass('active');

                                                            $maps.trigger('updateMarker.maps', [result.latitude, result.longitude, true]);
                                                        }
                                                    }]);
                                                }
                                            });

                                            if (results.responseJSON.output.length === 0) {
                                                $('[data-maps-message="no-results"]').show();
                                            } else {
                                                $('[data-maps-message="no-results"]').hide();
                                            }
                                        } else {
                                            $('[data-maps-message="no-results"]').show();
                                        }

                                        $maps.trigger('bindZoomMarkers.maps');
                                    }
                                }, this)
                            });

                            return false;
                        }).trigger('submit');
                    }
                }
            },

            /**
             * Submit filter.
             *
             * @param element
             */
            onSubmitFilter: function (element) {
                $(element.currentTarget).parents('form').trigger('submit');
            },

            /**
             * Trigger scroll hash to anchor attribute
             */
            onClickAnhorHash: function (element) {
                if(element.currentTarget.closest('#fullpagemenu')) {
                    return;
                }

                if (element.currentTarget.hash.substr(1).length > 1) {
                    var $element = $('[data-anchor="' + element.currentTarget.hash.substr(1) + '"]'),
                        heightHeader = $('.header').outerHeight() + 20;

                    if (Site.$anchorNavigation.length) {
                        heightHeader = Site.$anchorNavigation.outerHeight() + $('.header').outerHeight();
                    }

                    if ($element.length) {
                        $('html, body').animate({
                            scrollTop: $element.offset().top - heightHeader
                        }, 500);
                    }

                    return false;
                }

                return true;
            },

            /**
             * Init the anchor navigation.
             */
            initAnchorNavigation: function () {
                var $window = $(window);
                var heightHeader = this.$anchorNavigation.outerHeight() + $('.header').outerHeight() + 15;

                this.$anchorNavigation.each($.proxy(function (i, navigation) {
                    var $navigation         = $(navigation);
                    var $navigationItems    = $('li a', $navigation);
                    var $anchors            = [];
                    var $targets            = [];

                    $navigationItems.each($.proxy(function (ii, anchor) {
                        var $anchor  = $(anchor);
                        var $target  = $('[data-anchor="' + $anchor.attr('href').substr(1) + '"]');

                        if ($target.length) {
                            $anchor.on('click', $.proxy(function () {
                                if ($(window).width() < 992) {
                                    heightHeader = this.$anchorNavigation.outerHeight() + $('.header').outerHeight();
                                }

                                $('html, body').animate({
                                    scrollTop : $target.offset().top - heightHeader
                                }, 500);

                                $navigationItems.parent('li').removeClass('active');

                                $anchor.parent('li').addClass('active');

                                return false;
                            }, this));

                            $anchors.push($anchor);
                            $targets.push($target);
                        }
                    }, this));

                    $window.on('scroll', $.proxy(function () {
                        var scrollTop = $window.scrollTop() + heightHeader + this.$anchorNavigation.outerHeight();
                        heightHeader  = this.$anchorNavigation.outerHeight() + $('.header').outerHeight() + 15;

                        $targets.forEach($.proxy(function ($target, ii) {
                            var offset = $target.offset().top - 60;
                            var height = $target.outerHeight();

                            if (scrollTop >= offset && scrollTop <= offset + height) {
                                $anchors[ii].parent('li').addClass('active');
                            } else {
                                $anchors[ii].parent('li').removeClass('active');
                            }
                        }, this));
                    }, this)).trigger('scroll');
                }, this));
            },

            /**
             * Init the responsive tables.
             */
            initResponsiveTables: function () {
                this.$responsiveTables.each(function (i, table) {
                    $(table).wrap('<div class="table-wrapper" />');
                });
            },

            /**
             * Init the responsive embed videos.
             */
            initResponsiveEmbedVideos: function () {
                this.$responsiveEmbedVideos.each(function (i, video) {
                    $(video).wrap('<div class="video-wrapper" />').wrap('<div class="video-wrapper__inner" />');
                });
            },

            /**
             * Toggle search products form.
             *
             * @param element
             */
            onToggleSearchProducts: function (element) {
                var $element        = $(element.currentTarget);
                var $container      = $element.parents('.search-products');

                $container.addClass('active');

                $('[data-form]', $container).removeClass('active')
                    .filter('[data-form="' + $element.attr('data-form') + '"]').addClass('active')
                    .find('.active')
                    .removeClass('active');

                $('button[data-form]', $container).parent('li').removeClass('active');

                $('button[data-form]', $container).filter($element).parents('li').addClass('active');
            },

            /**
             * Toggle switch search products form.
             *
             * @param element
             */
            onSwitchSearchProducts: function (element) {
                var $element    = $(element.currentTarget);
                var $container  = $element.parents('.search-products');

                $container.removeClass('active');

                $('.active', $container).removeClass('active');
            },

            /**
             * Toggle search products sub form.
             *
             * @param element
             */
            onToggleSearchSubProducts: function (element) {
                var $element    = $(element.currentTarget);
                var $container  = $element.parents('.search-box');

                $('.search-box__options', $container).addClass('active');

                $('[data-subform]').removeClass('active')
                    .filter('[data-subform="' + $element.attr('data-subform') + '"]').addClass('active');
            },

            /**
             * Init animation taggbox
             */
            initAnimationTaggbox: function () {
                this.$blockTaggbox.each(function (i, element) {
                    var $trigger = $(element);

                    gsap.timeline({
                        scrollTrigger: {
                            trigger : $trigger,
                            start   : 'top center',
                            end     : 'center top',
                            markers : false,
                            scrub   : true
                        }
                    }).to($trigger, { x: -window.outerWidth });
                });
            },

            /**
             * Init animation hero text
             */
            initBlockHighlights: function () {
                this.$blockHighlights.each(function (i, element) {
                    var $trigger = $(element);

                    gsap.timeline({
                        scrollTrigger: {
                            trigger : $trigger,
                            start   : '-=250 center',
                            end     : '10% center',
                            markers : false,
                            scrub   : true
                        }
                    }).fromTo($trigger, {
                        x       : -(window.outerWidth - 100),
                        opacity : 0
                    }, {
                        x       : 0,
                        opacity : 1
                    });
                });
            },

            /**
             * Init pagination blocks
             */
            initBlockPaginations: function () {
                this.$blockPaginations.each(function (i, pagination) {
                    var $pagination = $(pagination);
                    var $element    = $pagination.prev('.block--filter');

                    if ($element.length && $element.attr('data-anchor')) {
                        $('a', $pagination).each(function (ii, link) {
                            var $link = $(link);

                            $link.attr('href', $link.attr('href') + '#' + $element.attr('data-anchor'));
                        });
                    }
                });
            },

            /**
             * Fill select car code finder
             */
            initSelectCarCodeFinder: function () {
                var $select     = $('[data-car-code-brands]');
                var $container  = $('[data-car-code-points]');
                var $brand      = $('.block--filter, .search-products').find('select[name="brand"]');

                if ($select.length) {
                    $select.on('change', $.proxy(function (event) {
                        this.onChangeSelectCarCode($(event.currentTarget).val());
                    }, this));

                    $.ajax({
                        url         : '/assets/components/motip/connector.php',
                        data        : {
                            action      : 'web/carcodes'
                        },
                        dataType    : 'JSON',
                        complete    : $.proxy(function (result) {
                            if (result.responseJSON) {
                                $.each(result.responseJSON.results, function (index, brand) {
                                    $select.append($('<option value="' + brand.index + '">' + brand.brand + '</option>'));

                                    $.each(brand.points, function (index2, point) {
                                        if (point.left && point.top) {
                                            $container.append($('<div class="point-item" style="left: ' + point.left + '%; top: ' + point.top + '%;"></div>'));
                                        }
                                    });
                                });

                                if ($brand.length && $brand.val()) {
                                    var value = $select.find('option').filter(function () {
                                        var optionText = $(this).text().toUpperCase();
                                        var brandText  = $brand.val().toUpperCase();
                                        return optionText === brandText;
                                    }).first().attr('value');
                                    $select.val(value).change();
                                }
                            }
                        }, this)
                    });
                }
            },

            /**
             * On change car code color select
             *
             * @param integer id
             */
            onChangeSelectCarCode: function (id) {
                var $container  = $('[data-car-code-points]');

                $.ajax({
                    url         : '/assets/components/motip/connector.php',
                    data        : {
                        action      : 'web/carcodes',
                        id          : id
                    },
                    dataType    : 'JSON',
                    complete    : $.proxy(function (result) {
                        if (result.responseJSON) {
                            $container.empty();

                            $.each(result.responseJSON.results, function (index, brand) {
                                $('[data-car-code-title]').html(brand.title);
                                $('[data-car-code-description]').html('<p>' + brand.description + '</p>');

                                $.each(brand.points, function (index2, point) {
                                    if (point.left && point.top) {
                                        var $point = $('<div class="point-item active" style="left: ' + point.left + '%; top: ' + point.top + '%;"></div>');

                                        if (point.position === 'left') {
                                            $point.addClass('point-item--left');
                                        }

                                        if (point.top < 25 && point.left > 40) {
                                            $point.addClass('point-item--small');
                                        }

                                        if (point.label !== '') {
                                            $point.append(
                                                $('<div class="point-item__wrap">'
                                                    + '<span>' + point.label + '</span>'
                                                + '</div>')
                                            );
                                        }

                                        $container.append($point);
                                    }
                                });
                            });
                        }
                    }, this)
                });
            },

            /**
             * Toggle modal.
             *
             * @param element
             */
            onToggleModal: function (element) {
                var $element    = $(element.currentTarget);
                var $container  = $('[data-modal-container]');
                var $target     = $('[data-modal-target="' + $element.data('modal') + '"]');

                if ($target.length) {
                    Site.$body.addClass('modal-active');

                    $container.append($target.clone(true)).addClass('active');

                    /* Set values for select inside modal */
                    $target.last().find('select').each(function () {
                        $container.find('select[name="' + $(this).attr('name') + '"]').val($(this).val());
                    });
                } else {
                    Site.$body.removeClass('modal-active');

                    $container.empty().removeClass('active');
                }

                return false;
            },

            /**
             * Toggle active campaign chat button.
             */
            onToggleChat: function () {
                var $iframe = $('#conversations-iframe');

                if ($iframe) {
                    $iframe.contents().find('.widget_icon_container').click();
                }

                return false;
            },

            /**
             * Toggle sub specs in the specs list.
             *
             * @param element
             */
            onToggleSpecs: function (element) {
                $(element.currentTarget).parent('li').toggleClass('specs-active');

                $(element.currentTarget).find('> ul').stop().slideToggle();

                return false;
            },

            /**
             * Toggle visible shops list.
             *
             */
            onToggleShops: function () {
                var $shopsList = $('.shop-list');
                $shopsList.toggleClass('active');

                return false;
            },

            /**
             * Toggle visible informer block.
             *
             */
            onToggleInformer: function (e) {
                var $target = $(e.currentTarget);
                $target.parent().removeClass('active');

                Cookies.set('segment_' + current_segment + "_popup", true, { path: '/', expires: 7});

                return false;
            },

            /**
             * Toggle visible catalog filters.
             *
             */
            onToggleFilters: function () {
                var $shopsList = $('.filters');
                $shopsList.toggleClass('active');

                return false;
            },

            /**
             * Toggle visible panels.
             *
             */
            onTogglePanel: function (element) {
                var $element = $(element.currentTarget);
                var data = $element.data('toggle-panel');
                var $panel = $('[data-panel="' + data + '"]');

                $panel.toggleClass('active');

                if (data === 'search') {
                    var $input = $('input', $panel);

                    if ($input.length) {
                        setTimeout(function () {
                            $input.focus();
                        }, 500);
                    }
                }

                return false;
            },

            onToggleTab: function (element) {
                var $element = $(element.currentTarget);
                var data = $element.data('tab');
                var $tabContent = $('[data-tab-content="' + data + '"]');
                var $creativeTitle = document.getElementsByClassName('creative__title')[0].querySelector('.is-h2');

                $('[data-tab]').removeClass('active');
                $element.addClass('active');

                $('[data-tab-content]').removeClass('active');
                $tabContent.addClass('active');
                $creativeTitle.innerHTML = $tabContent[0].dataset.title;

                gsap.set($('[data-tab-content].active'), { opacity: 1, delay: 0.1 });
                gsap.from($('[data-tab-content]'), 1, { opacity: 0, delay: 0.1 });
                gsap.to($('[data-tab-content].active'), 1, { opacity: 1, delay: 0.1 });

                return false;
            },

            /**
             * Hide panel click Escape
             *
             */
            onClosePanelEsc: function (e) {
                var $panel = $('.panel');

                if (e.key === "Escape" && $panel && $panel.hasClass('active')) {
                    $panel.removeClass('active');
                }

                return false;
            },

            /**
             * Init auto suggestion fields
             */
            initAutoSuggestionFields: function () {
                $('[data-auto-suggestion]').each($.proxy(function (index, input) {
                    var $input      = $(input);
                    var type        = $input.attr('data-auto-suggestion');
                    var context     = this.$body.attr('data-context');
                    var $wrapper    = $input.wrap($('<div class="form-element-suggestions">')).parent('.form-element-suggestions');
                    var $container  = $('<div class="form-element-suggestions__content">');

                    $wrapper.append(
                        $container
                    );

                    $input.on('keyup', $.proxy(function () {
                        var value = $input.val();

                        if (value.length > 2) {
                            $.ajax({
                                url         : '/assets/components/motip/connector.php',
                                data        : {
                                    action      : 'web/suggestion',
                                    type        : type,
                                    query       : value,
                                    context     : context
                                },
                                dataType    : 'JSON',
                                complete    : $.proxy(function (results) {
                                    $wrapper.removeClass('active');

                                    if (results.responseJSON) {
                                        $container.empty();

                                        if (results.responseJSON.results.html) {
                                            var $html = $(results.responseJSON.results.html);

                                            $('[data-value]', $html).on('click', function (event) {
                                                $input.val($(event.currentTarget).attr('data-value'));

                                                return false;
                                            });

                                            $container.append($html);

                                            $wrapper.addClass('active');
                                        }
                                    }
                                }, this)
                            });
                        } else {
                            $wrapper.removeClass('active');
                        }
                    }, this)).on('blur', $.proxy(function () {
                        setTimeout(function () {
                            $wrapper.removeClass('active');
                        }, 250);
                    }, this));
                }, this));
            },

            /**
             * Init active campaign forms
             */
            initActiveCampaignForms: function () {
                $('[data-active-campaign]').each($.proxy(function (index, form) {
                    var $form = $(form);

                    $.getScript('https://motip.activehosted.com/f/embed.php?id=' + $form.attr('data-active-campaign'), function () {
                        var $style = $('style', $form);

                        if ($style) {
                            $style.remove();
                        }
                    });
                }, this));
            },

            /**
             * Redirect to selected product
             */
            onChangeCountryLanguage: function (element) {
                document.location.href = $(element.currentTarget).val();
            },

            onResetForm: function (element) {
                setTimeout(function () {
                    $(element.currentTarget).find('select, input').val('');
                    $(element.currentTarget).find('.selectpicker').selectpicker('val', '');
                }, 0);
            },

            initHash: function () {
                if (window.location.hash.substr(1).length > 1) {
                    var $element = $('[data-anchor="' + window.location.hash.substr(1) + '"]'),
                        heightHeader = $('.header').outerHeight() + 20;

                    if ($element.length) {
                        $('html, body').animate({
                            scrollTop: $element.offset().top - heightHeader
                        }, 500);
                    }
                }
            },

            /**
             * Hide content on color guide page if user hase already seen it.
             */
            initColorguideHideContent: function () {
                if (!this.hasCookiesEnabled()) {
                    return false;
                }

                var filterBlocks = $('.block--filter');

                if (!Cookies.get('motip_hide_content')) {
                    /* Set cookie when user read content first time */
                    if (filterBlocks.first().length > 0) {
                        $(window).scroll($.proxy(function () {
                            var currentScroll = $(window).scrollTop();

                            if (currentScroll >= filterBlocks.first().offset().top) {
                                Cookies.set('motip_hide_content', true, { path: window.location.pathname });
                            }
                        }, this)).trigger('scroll');
                    }
                } else {
                    /* Hide content if cookie is set */
                    filterBlocks.each(function () {
                        var blocks = $(this).parent().find('.block');
                        for (var i = 0; i < $(this).index(); i++) {
                            if (!$(blocks[i]).hasClass('block--filter')) {
                                $(blocks[i]).hide();
                            }
                        }
                    });
                }

                return true;
            },

            /**
             * Change car brand in car-code-finder modal
             */
            initBrandSelect: function () {
                var $select     = $('.block--filter, .search-products').find('select[name="brand"]');
                var $target     = $('[data-car-code-brands]');

                if ($select.length) {
                    $select.on('change', $.proxy(function (event) {
                        var value = $target.find('option').filter(function () {
                            var optionText = $(this).text().toUpperCase();
                            var valueText  = $(event.currentTarget).val().toUpperCase();
                            return optionText === valueText;
                        }).first().attr('value');
                        $target.val(value).change();
                    }, this));
                }
            },


            /**
             * Init tooltip
             */
            initTooltip: function () {
                this.$tooltip.each(function (index, el) {
                    tippy(el, {
                        trigger: 'click',
                        interactive: true,
                        onShow(instance) {
                            const $content = instance.popper.querySelector('.tippy-box');
                            const closeEl = document.createElement('div');
                            closeEl.className = 'tippy-close';

                            $content.appendChild(closeEl);

                            instance.popper.querySelector('.tippy-close').addEventListener('click', () => {
                                instance.hide();
                            });
                        },
                        onHide(instance) {
                            const $close = instance.popper.querySelector('.tippy-close');
                            $close.removeEventListener('click', () => {
                                instance.hide();
                            });
                            $close.remove();
                        },
                    });
                });
            },

            /**
             * Init Fullpage.
             */
            initFullpage: function () {
                const excessiveWidth = document.documentElement.clientWidth  >= this.fullpageMaxWidth;
                const enoughHeight   = document.documentElement.clientHeight >= this.fullpageMinHeight;
                const $el  = document.getElementById('fullpage');
                const $nav = document.getElementById('fullpagemenu');

                if (!excessiveWidth && enoughHeight && $el && $nav) {
                    this.$Fullpage = new Fullpage($el, {
                        licenseKey: 'R0LHJ-6KUTI-RLI9J-W5KP8-RGNDL',
                        lockAnchors: true,
                        scrollOverflow: true,
                        /* anchors: [...$nav.querySelectorAll('[data-menuanchor]')].map((i) => {
                          return i.dataset.menuanchor
                        }),
                        menu: '#' + $nav.id, */
                    });
                    return true;
                }

                $('.fp-overflow').removeClass('fp-overflow');
                return false;
            },

            onToggleShowAll: function (event) {
                event.preventDefault();
                let parent = $(event.currentTarget).parent();

                if (parent) {
                    parent.toggleClass('active');
                    $(event.currentTarget).hide();
                }
            },

            /**
             * Init Gallery.
             */
            initGallery: function () {
                var swiper = new Swiper(this.$galleryThumbnail[0], {
                    slidesPerView: 'auto',
                    freeMode: true,
                    watchSlidesProgress: true,
                    spaceBetween: 18,
                    navigation      : {
                        nextEl          : $('.swiper-button-next', this.$galleryThumbnail[0])[0],
                        prevEl          : $('.swiper-button-prev', this.$galleryThumbnail[0])[0]
                    }
                });
                /* eslint-disable no-unused-vars */
                var swiper2 = new Swiper(this.$gallery[0], {
                    thumbs: {
                        swiper: swiper
                    }
                });
            },

            /**
             * Init call.
             */
            init: function () {
                this.initWindowScroll();
                this.initWindowZoom();
                this.initSelectCarCodeFinder();

                this.initLightCase();
                this.initMultiSelect();

                this.initSliderProducts();
                this.initSliderMarkets();
                this.initSliderHowTo();

                this.initNavigator();
                this.initCountryCheck();

                this.initAnchorNavigation();

                this.initResponsiveTables();
                this.initResponsiveEmbedVideos();

                this.initAnimationTaggbox();
                this.initBlockHighlights();
                this.initBlockPaginations();
                this.initAutoSuggestionFields();
                this.initActiveCampaignForms();
                this.initBrandSelect();

                const hasFP = this.initFullpage();
                if (!hasFP) {
                  this.initHash();
                }

                this.initTooltip();
                this.initGallery();

                if ($('#colour-guide').length > 0) {
                    this.initColorguideHideContent();
                }
            }
        };

        $(document).on({
            click: $.proxy(Site, 'onToggleShowAll')
        }, '.show-all')

        $(document).on({
            click: $.proxy(Site, 'onToggleSearchForm')
        }, '.search-toggle');

        $(document).on({
            submit: $.proxy(Site, 'onSubmitSearchForm')
        }, '.form-search');

        $(document).on({
            click: $.proxy(Site, 'onToggleNavigation')
        }, '.nav-toggle');

        $(document).on({
            click: $.proxy(Site, 'onToggleSubNavigation')
        }, '.has-subnav .toggle-subnav');

        $(document).on({
            click: $.proxy(Site, 'onToggleBrandList')
        }, '.brands-toggle > button');

        $(document).on({
            click: $.proxy(Site, 'onToggleLanguage')
        }, '[data-language-toggle]');

        $(document).on({
            click: $.proxy(Site, 'onToggleSearchProducts')
        }, '.search-products button[data-form]');

        $(document).on({
            click: $.proxy(Site, 'onSwitchSearchProducts')
        }, '.search-products button[data-form-switch]');

        $(document).on({
            click: $.proxy(Site, 'onToggleSearchSubProducts')
        }, '.search-box button[data-subform]');

        $(document).on({
            click: $.proxy(Site, 'onToggleSocialShare')
        }, '[data-social-share-toggle]');

        $(document).on({
            click: $.proxy(Site, 'onHandleScrollNext')
        }, '[data-scroll]');

        $(document).on({
            click: $.proxy(Site, 'onHandleSocialShareUrl')
        }, '[data-social-share-url]');

        $(document).on({
            click: $.proxy(Site, 'onHandleHash')
        }, '.richtext a:not(".naw-item")[href^="#"]');

        $(document).on({
            click: $.proxy(Site, 'onHandleHash')
        }, '.faq__header a[href^="#"]');

        $(document).on({
            click: $.proxy(Site, 'onClickAnhorHash')
        }, 'div:not(.richtext):not(#lightcase-nav) [href^="#"]');

        $(document).on({
            click: $.proxy(Site, 'onToggleFaqQuestion')
        }, '[data-faq-question-toggle]');

        $(document).on({
            click: $.proxy(Site, 'onToggleModal')
        }, '[data-modal]');

        $(document).on({
            click: $.proxy(Site, 'onToggleChat')
        }, '[data-chat]');

        $(document).on({
            click: $.proxy(Site, 'onToggleSpecs')
        }, '.has-specs .toggle-specs');

        $(document).on({
            change: $.proxy(Site, 'onSubmitFilter')
        }, '[data-filter] select');

        $(document).on({
            change: $.proxy(Site, 'onChangeCountryLanguage')
        }, '.popup--countries select[name="language"]');

        $(document).on({
            reset: $.proxy(Site, 'onResetForm')
        }, '.form');

        $(document).on({
            click: $.proxy(Site, 'onToggleShops')
        }, '[data-toggle-shop]');

        $(document).on({
            click: $.proxy(Site, 'onToggleInformer')
        }, '[data-informer-close]');

        $(document).on({
            click: $.proxy(Site, 'onToggleFilters')
        }, '[data-toggle-filters]');

        $(document).on({
            click: $.proxy(Site, 'onTogglePanel')
        }, '[data-toggle-panel]');

        $(document).on({
            click: $.proxy(Site, 'onToggleTab')
        }, '[data-tab]');

        $(document).on({
            keyup: $.proxy(Site, 'onClosePanelEsc')
        });

        window.onload = function () {
            Site.init();
        };
    }(jQuery)
);
